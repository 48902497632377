@import url('https://fonts.googleapis.com/css2?family=Reggae+One&display=swap');

body {
  margin: 0;
  font-family: "Reggae One", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: orange;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NoMargin {
  margin: 0;
}

.ContentWrap {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContentMain {
  width: 90%;
  max-height: 100%;
  text-align: center;
}

.HalloweenTop {
  width: 100%;
  max-width: 80vh;
  max-height: 80vh;
  // margin: auto;
  // display: block;
}

.Button {
  background-color: orangered;
  margin: auto;
  text-align: center;
  border-radius: 1rem;

  a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 5px 15px;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  }
}

.Doors {

  display: flex;
  justify-content: space-evenly;

  .Door {
    width: 40vw;
  }
}

.Result {
  max-width: 80%;
  max-height: 40vh;
}

.ResultImage {
  max-width: 100%;
  max-height: 40vh;
}

.Explain {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 1.5rem;
  color: #639;
}